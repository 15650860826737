import { useState } from "react";
import styled from "styled-components";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import MainHeading from "../components/UI/MainHeading";
import DownloadButton from "../components/UI/Buttons/DownloadButton";
import InputField from "../components/UI/Form/InputField";
import DropDown from "../components/UI/Form/DropDown";
import ColorPicker from "../components/UI/Form/ColorPicker";
import SwitchField from "../components/UI/Form/SwitchField";
import Align from "../components/UI/Form/Align";
import FontStyle from "../components/UI/Form/FontStyle";
import InputSlider from "../components/UI/Form/InputSlider";

const Home = () => {
  const [state, setState] = useState({
    // unlock pro
    licenseKey: "",
    // content formatting
    showAsText: false,
    outlineColor: "#000000",
    fullWidth: true,
    hideScrollBar: true,
    // font formatting
    fontColor: "#000000",
    fontSize: 12,
    fontFamily: "Segoe UI",
    fontAlign: "Left",
    // grid
    showGrid: false,
    outlineThickness: 1,
    rowPadding: 1,
    columnPadding: 1,
    scalingPercentage: 100,
    // properties
    lockAspect: false,
    keepLayerOrder: false,
    // title
    showTitle: false,
    // titleText: "",
    titleFontFamily: "Segoe UI",
    titleFontSize: 12,
    titleBold: false,
    titleItalic: false,
    titleUnderline: false,
    titleFontColor: "#000000",
    titleBackground: "#000000",
    titleAlignment: "Left",
    titleWrap: false,
    // effects
    // background
    showBackground: false,
    backgroundColor: "#000000",
    backgroundTransparency: 0,
    // visual border
    showVisualBorder: false,
    visualBorderColor: "#000000",
    visualBorderRadius: 0,
    // shadow
    showShadow: false,
    showShadowColor: "#000000",
    showShadowPosition: "Inner",
    showShadowPreset: "Custom",
    showShadowSize: 12,
    showShadoBlur: 0,
    showShadowAngle: 0,
    showShadowDistance: 0,
    showShadowTransparency: 0,
    // visual header
    showVisualHeader: false,
    visualHeaderBackground: "#000000",
    visualHeaderBorder: "#000000",
    visualHeaderForeground: "#000000",
    visualHeaderTransparency: 0,
    showVisualInformationButton: false,
    showVisualWarningButton: false,
    showVisualErrorButton: false,
    showDrillRoleSelector: false,
    showDrillUpButton: false,
    showDrillToggleButton: false,
    showDrillDownLevelButton: false,
    showDrillDownExpandButton: false,
    showPinButton: false,
    showFocusModeButton: false,
    showSeeDataLayoutToggleButton: false,
    showOptionsMenu: false,
    showFilterRestatementButton: false,
    showTooltipButton: false,
    //help tooltip
    visualHeaderTooltipFontFamily: "Segoe UI",
    visualHeaderTooltipFontSize: 12,
    visualHeaderTooltipColor: "#000000",
    visualHeaderTooltipBackground: "#000000",
    visualHeaderTooltipTransparency: 0,
    //visual tooltip
    showVisualTooltip: false,
    visualType: "Default",
    visualTypeFontFamily: "Segoe UI",
    visualTypeFontSize: 12,
    visualTypeBold: false,
    visualTypeItalic: false,
    visualTypeUnderline: false,
    visualTypeTitleFontColor: "#000000",
    visualTypeValueFontColor: "#000000",
    visualTypeBackground: "#000000",
    visualTypeTransparency: 0,
  });

  const checkDisabled = () => {
    if (state.licenseKey) {
      return false;
    }
    return true;
  };

  const offsetList = [
    { value: "Inner", name: "Inside" },
    { value: "Outside", name: "Outside" },
  ];

  const presetList = [{ value: "Custom" }];

  const tooltiptList = [{ value: "Default" }];

  const fontFamilyList = [
    { value: "Arial" },
    { value: "Arial Black" },
    { value: "Arial Unicode MS" },
    { value: "Calibri" },
    { value: "Cambria" },
    { value: "Cambria Math" },
    { value: "Candara" },
    { value: "Comic Sans MS" },
    { value: "Consolas" },
    { value: "Constantia" },
    { value: "Corbel" },
    { value: "Courier New" },
    { value: "Georgia" },
    { value: "Lucida Sans Unicode" },
    { value: "Segoe (Bold)" },
    { value: "Segoe UI" },
    { value: "Segoe UI Light" },
    { value: "Symbol" },
    { value: "Tahoma" },
    { value: "Times New Roman" },
    { value: "Trebuchet MS" },
    { value: "Verdana" },
    { value: "Wingdings" },
  ];

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <PageWrapper>
      <MainHeading heading="HTML Text Styler Theme Generator" />
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Visual" />
        <Tab label="General" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Accordion className="html-text-styler-accordion">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Unlock Pro</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InputField
              heading="License Key"
              placeholder="License Key"
              type="text"
              isDisabled={false}
              defaultValue={state.licenseKey}
              passInputFieldData={(data) =>
                setState({ ...state, licenseKey: data })
              }
            />
          </AccordionDetails>
        </Accordion>
        <Accordion className="html-text-styler-accordion">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Content Formatting</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SwitchField
              heading="Show as Text"
              isAccordionSwitch={false}
              isDisabled={false}
              defaultValue={state.showAsText}
              passSwitchFieldData={(data) =>
                setState({ ...state, showAsText: data })
              }
            />
            <InputField
              heading="Scaling Percentage"
              placeholder="Scaling Percentage"
              type="number"
              isDisabled={false}
              defaultValue={state.scalingPercentage}
              passInputFieldData={(data) =>
                setState({ ...state, scalingPercentage: data })
              }
            />
            <SwitchField
              heading="Full Width"
              isAccordionSwitch={false}
              isDisabled={false}
              defaultValue={state.fullWidth}
              passSwitchFieldData={(data) =>
                setState({ ...state, fullWidth: data })
              }
            />
            <SwitchField
              heading="Hide Scroll Bar"
              isAccordionSwitch={false}
              isDisabled={false}
              defaultValue={state.hideScrollBar}
              passSwitchFieldData={(data) =>
                setState({ ...state, hideScrollBar: data })
              }
            />
          </AccordionDetails>
        </Accordion>
        <Accordion className="html-text-styler-accordion">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Font Formatting</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ColorPicker
              heading="Default Font Color"
              defaultValue={state.fontColor}
              isDisabled={false}
              passColorPickerData={(data) =>
                setState({ ...state, fontColor: data })
              }
            />
            <InputField
              heading="Font Size"
              placeholder="Font Size"
              type="number"
              isDisabled={false}
              defaultValue={state.fontSize}
              passInputFieldData={(data) =>
                setState({ ...state, fontSize: data })
              }
            />
            <DropDown
              heading="Font Family"
              isDisabled={false}
              options={fontFamilyList}
              defaultValue={state.fontFamily}
              passDropDownData={(data) =>
                setState({ ...state, fontFamily: data })
              }
            />
            <Align
              heading="Default Text Align"
              isDisabled={false}
              defaultValue={state.fontAlign}
              passAlignData={(data) => setState({ ...state, fontAlign: data })}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion className="html-text-styler-accordion">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Grid</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SwitchField
              isAccordionSwitch={true}
              isDisabled={false}
              heading=""
              defaultValue={state.showGrid}
              passSwitchFieldData={(data) =>
                setState({ ...state, showGrid: data })
              }
            />
            <InputField
              heading="Outline Thickness"
              placeholder="Outline Thickness"
              type="number"
              isDisabled={!state.showGrid}
              defaultValue={state.outlineThickness}
              passInputFieldData={(data) =>
                setState({ ...state, outlineThickness: data })
              }
            />
            <InputField
              heading="Row Padding"
              placeholder="Row Padding"
              type="number"
              isDisabled={!state.showGrid}
              defaultValue={state.rowPadding}
              passInputFieldData={(data) =>
                setState({ ...state, rowPadding: data })
              }
            />
            <InputField
              heading="Column Padding"
              placeholder="Column Padding"
              type="number"
              isDisabled={!state.showGrid}
              defaultValue={state.columnPadding}
              passInputFieldData={(data) =>
                setState({ ...state, columnPadding: data })
              }
            />
            <ColorPicker
              heading="Default Outline Color"
              isDisabled={!state.showGrid}
              defaultValue={state.outlineColor}
              passColorPickerData={(data) =>
                setState({ ...state, outlineColor: data })
              }
            />
          </AccordionDetails>
        </Accordion>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Accordion className="html-text-styler-accordion">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Properties</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SwitchField
              heading="Lock aspect ratio"
              isAccordionSwitch={false}
              isDisabled={false}
              defaultValue={state.lockAspect}
              passSwitchFieldData={(data) =>
                setState({ ...state, lockAspect: data })
              }
            />
            <SwitchField
              heading="Maintain layer order"
              isAccordionSwitch={false}
              isDisabled={false}
              defaultValue={state.keepLayerOrder}
              passSwitchFieldData={(data) =>
                setState({ ...state, keepLayerOrder: data })
              }
            />
          </AccordionDetails>
        </Accordion>
        <Accordion className="html-text-styler-accordion">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Title</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SwitchField
              isAccordionSwitch={true}
              isDisabled={false}
              heading=""
              defaultValue={state.showTitle}
              passSwitchFieldData={(data) =>
                setState({ ...state, showTitle: data })
              }
            />
            {/* <InputField
              heading="Text"
              placeholder="Text"
              type="text"
              isDisabled={!state.showTitle}
              defaultValue={state.titleText}
              passInputFieldData={(data) =>
                setState({ ...state, titleText: data })
              }
            /> */}
            <DropDown
              heading="Font Family"
              options={fontFamilyList}
              isDisabled={!state.showTitle}
              defaultValue={state.titleFontFamily}
              passDropDownData={(data) =>
                setState({ ...state, titleFontFamily: data })
              }
            />
            <InputField
              heading="Font Size"
              placeholder="Font Size"
              type="number"
              isDisabled={!state.showTitle}
              defaultValue={state.titleFontSize}
              passInputFieldData={(data) =>
                setState({ ...state, titleFontSize: data })
              }
            />
            <FontStyle
              heading="Font Style"
              isDisabled={!state.showTitle}
              defaultBoldValue={state.titleBold}
              defaultItalicValue={state.titleItalic}
              defaultUnderlineValue={state.titleUnderline}
              passBoldData={(data) => setState({ ...state, titleBold: data })}
              passItalicData={(data) =>
                setState({ ...state, titleItalic: data })
              }
              passUnderlineData={(data) =>
                setState({ ...state, titleUnderline: data })
              }
            />
            <ColorPicker
              heading="Text"
              isDisabled={!state.showTitle}
              defaultValue={state.titleFontColor}
              passColorPickerData={(data) =>
                setState({ ...state, titleFontColor: data })
              }
            />
            <ColorPicker
              heading="Background"
              isDisabled={!state.showTitle}
              defaultValue={state.titleBackground}
              passColorPickerData={(data) =>
                setState({ ...state, titleBackground: data })
              }
            />
            <Align
              heading="Horizontal Alignment"
              isDisabled={!state.showTitle}
              defaultValue={state.titleAlignment}
              passAlignData={(data) =>
                setState({ ...state, titleAlignment: data })
              }
            />
            <SwitchField
              heading="Text Wrapper"
              isAccordionSwitch={false}
              isDisabled={!state.showTitle}
              defaultValue={state.titleWrap}
              passSwitchFieldData={(data) =>
                setState({ ...state, titleWrap: data })
              }
            />
          </AccordionDetails>
        </Accordion>
        <Accordion className="html-text-styler-accordion html-text-styler-subaccordion">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Effects</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Accordion className="html-text-styler-accordion">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Background</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SwitchField
                  isAccordionSwitch={true}
                  isDisabled={false}
                  heading=""
                  defaultValue={state.showBackground}
                  passSwitchFieldData={(data) =>
                    setState({ ...state, showBackground: data })
                  }
                />
                <ColorPicker
                  heading="Color"
                  isDisabled={!state.showBackground}
                  defaultValue={state.backgroundColor}
                  passColorPickerData={(data) =>
                    setState({ ...state, backgroundColor: data })
                  }
                />
                <InputSlider
                  heading="Transparency"
                  placeholder="Transparency"
                  isDisabled={!state.showBackground}
                  defaultValue={state.backgroundTransparency}
                  passInputFieldData={(data) =>
                    setState({ ...state, backgroundTransparency: data })
                  }
                  maxValue={100}
                  suffix="%"
                />
              </AccordionDetails>
            </Accordion>
            <Accordion className="html-text-styler-accordion">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Visual border</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SwitchField
                  isAccordionSwitch={true}
                  isDisabled={false}
                  heading=""
                  defaultValue={state.showVisualBorder}
                  passSwitchFieldData={(data) =>
                    setState({ ...state, showVisualBorder: data })
                  }
                />
                <ColorPicker
                  heading="Color"
                  isDisabled={!state.showVisualBorder}
                  defaultValue={state.visualBorderColor}
                  passColorPickerData={(data) =>
                    setState({ ...state, visualBorderColor: data })
                  }
                />
                <InputSlider
                  heading="Rounded Corners"
                  placeholder="Rounded Corners"
                  isDisabled={!state.showVisualBorder}
                  defaultValue={state.visualBorderRadius}
                  passInputFieldData={(data) =>
                    setState({
                      ...state,
                      visualBorderRadius: data,
                    })
                  }
                  maxValue={30}
                  suffix="px"
                />
              </AccordionDetails>
            </Accordion>
            <Accordion className="html-text-styler-accordion">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Shadow</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SwitchField
                  isAccordionSwitch={true}
                  isDisabled={false}
                  heading=""
                  defaultValue={state.showShadow}
                  passSwitchFieldData={(data) =>
                    setState({ ...state, showShadow: data })
                  }
                />
                <ColorPicker
                  heading="Color"
                  isDisabled={!state.showShadow}
                  defaultValue={state.showShadowColor}
                  passColorPickerData={(data) =>
                    setState({ ...state, showShadowColor: data })
                  }
                />
                <DropDown
                  heading="Offset"
                  isDisabled={!state.showShadow}
                  options={offsetList}
                  defaultValue={state.showShadowPosition}
                  passDropDownData={(data) =>
                    setState({ ...state, showShadowPosition: data })
                  }
                />
                <DropDown
                  heading="Preset"
                  isDisabled={!state.showShadow}
                  options={presetList}
                  defaultValue={state.showShadowPreset}
                  passDropDownData={(data) =>
                    setState({ ...state, showShadowPreset: data })
                  }
                />
                <InputField
                  heading="Size"
                  placeholder="Size"
                  type="number"
                  isDisabled={!state.showShadow}
                  defaultValue={state.showShadowSize}
                  passInputFieldData={(data) =>
                    setState({ ...state, showShadowSize: data })
                  }
                />
                <InputField
                  heading="Blur"
                  placeholder="Blur"
                  type="number"
                  isDisabled={!state.showShadow}
                  defaultValue={state.showShadoBlur}
                  passInputFieldData={(data) =>
                    setState({ ...state, showShadoBlur: data })
                  }
                />
                <InputField
                  heading="Angle"
                  placeholder="Angle"
                  type="number"
                  isDisabled={!state.showShadow}
                  defaultValue={state.showShadowAngle}
                  passInputFieldData={(data) =>
                    setState({ ...state, showShadowAngle: data })
                  }
                  maxValue={360}
                />
                <InputField
                  heading="Distance"
                  placeholder="Distance"
                  type="number"
                  isDisabled={!state.showShadow}
                  defaultValue={state.showShadowDistance}
                  passInputFieldData={(data) =>
                    setState({ ...state, showShadowDistance: data })
                  }
                />
                <InputSlider
                  heading="Transparency"
                  placeholder="Transparency"
                  isDisabled={!state.showShadow}
                  defaultValue={state.showShadowTransparency}
                  passInputFieldData={(data) =>
                    setState({ ...state, showShadowTransparency: data })
                  }
                  maxValue={100}
                  suffix="%"
                />
              </AccordionDetails>
            </Accordion>
          </AccordionDetails>
        </Accordion>
        <Accordion className="html-text-styler-accordion html-text-styler-subaccordion">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Header Icons</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SwitchField
              isAccordionSwitch={true}
              isDisabled={false}
              heading=""
              defaultValue={state.showVisualHeader}
              passSwitchFieldData={(data) =>
                setState({ ...state, showVisualHeader: data })
              }
            />
            <Accordion className="html-text-styler-accordion">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Colors</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ColorPicker
                  heading="Background"
                  isDisabled={!state.showVisualHeader}
                  defaultValue={state.visualHeaderBackground}
                  passColorPickerData={(data) =>
                    setState({ ...state, visualHeaderBackground: data })
                  }
                />
                <ColorPicker
                  heading="Border"
                  isDisabled={!state.showVisualHeader}
                  defaultValue={state.visualHeaderBorder}
                  passColorPickerData={(data) =>
                    setState({ ...state, visualHeaderBorder: data })
                  }
                />
                <ColorPicker
                  heading="Foreground"
                  isDisabled={!state.showVisualHeader}
                  defaultValue={state.visualHeaderForeground}
                  passColorPickerData={(data) =>
                    setState({ ...state, visualHeaderForeground: data })
                  }
                />
                <InputSlider
                  heading="Transparency"
                  placeholder="Transparency"
                  isDisabled={!state.showVisualHeader}
                  defaultValue={state.visualHeaderTransparency}
                  passInputFieldData={(data) =>
                    setState({ ...state, visualHeaderTransparency: data })
                  }
                  maxValue={100}
                  suffix="%"
                />
              </AccordionDetails>
            </Accordion>
            <Accordion className="html-text-styler-accordion">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Icons</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SwitchField
                  isAccordionSwitch={false}
                  isDisabled={!state.showVisualHeader}
                  heading="Visual information"
                  defaultValue={state.showVisualInformationButton}
                  passSwitchFieldData={(data) =>
                    setState({ ...state, showVisualInformationButton: data })
                  }
                />
                <SwitchField
                  isAccordionSwitch={false}
                  isDisabled={!state.showVisualHeader}
                  heading="Visual warning"
                  defaultValue={state.showVisualWarningButton}
                  passSwitchFieldData={(data) =>
                    setState({ ...state, showVisualWarningButton: data })
                  }
                />
                <SwitchField
                  isAccordionSwitch={false}
                  isDisabled={!state.showVisualHeader}
                  heading="Visual error"
                  defaultValue={state.showVisualErrorButton}
                  passSwitchFieldData={(data) =>
                    setState({ ...state, showVisualErrorButton: data })
                  }
                />
                <SwitchField
                  isAccordionSwitch={false}
                  isDisabled={!state.showVisualHeader}
                  heading="Drill on dropdown"
                  defaultValue={state.showDrillRoleSelector}
                  passSwitchFieldData={(data) =>
                    setState({ ...state, showDrillRoleSelector: data })
                  }
                />
                <SwitchField
                  isAccordionSwitch={false}
                  isDisabled={!state.showVisualHeader}
                  heading="Drill up"
                  defaultValue={state.showDrillUpButton}
                  passSwitchFieldData={(data) =>
                    setState({ ...state, showDrillUpButton: data })
                  }
                />
                <SwitchField
                  isAccordionSwitch={false}
                  isDisabled={!state.showVisualHeader}
                  heading="Drill down"
                  defaultValue={state.showDrillToggleButton}
                  passSwitchFieldData={(data) =>
                    setState({ ...state, showDrillToggleButton: data })
                  }
                />
                <SwitchField
                  isAccordionSwitch={false}
                  isDisabled={!state.showVisualHeader}
                  heading="Show next level"
                  defaultValue={state.showDrillDownLevelButton}
                  passSwitchFieldData={(data) =>
                    setState({ ...state, showDrillDownLevelButton: data })
                  }
                />
                <SwitchField
                  isAccordionSwitch={false}
                  isDisabled={!state.showVisualHeader}
                  heading="Expand to next level"
                  defaultValue={state.showDrillDownExpandButton}
                  passSwitchFieldData={(data) =>
                    setState({ ...state, showDrillDownExpandButton: data })
                  }
                />
                <SwitchField
                  isAccordionSwitch={false}
                  isDisabled={!state.showVisualHeader}
                  heading="Pin"
                  defaultValue={state.showPinButton}
                  passSwitchFieldData={(data) =>
                    setState({ ...state, showPinButton: data })
                  }
                />
                <SwitchField
                  isAccordionSwitch={false}
                  isDisabled={!state.showVisualHeader}
                  heading="Focus mode"
                  defaultValue={state.showFocusModeButton}
                  passSwitchFieldData={(data) =>
                    setState({ ...state, showFocusModeButton: data })
                  }
                />
                <SwitchField
                  isAccordionSwitch={false}
                  isDisabled={!state.showVisualHeader}
                  heading="See data layout"
                  defaultValue={state.showSeeDataLayoutToggleButton}
                  passSwitchFieldData={(data) =>
                    setState({ ...state, showSeeDataLayoutToggleButton: data })
                  }
                />
                <SwitchField
                  isAccordionSwitch={false}
                  isDisabled={!state.showVisualHeader}
                  heading="More options"
                  defaultValue={state.showOptionsMenu}
                  passSwitchFieldData={(data) =>
                    setState({ ...state, showOptionsMenu: data })
                  }
                />
                <SwitchField
                  isAccordionSwitch={false}
                  isDisabled={!state.showVisualHeader}
                  heading="Filter"
                  defaultValue={state.showFilterRestatementButton}
                  passSwitchFieldData={(data) =>
                    setState({ ...state, showFilterRestatementButton: data })
                  }
                />
                <SwitchField
                  isAccordionSwitch={false}
                  isDisabled={!state.showVisualHeader}
                  heading="Help tooltip"
                  defaultValue={state.showTooltipButton}
                  passSwitchFieldData={(data) =>
                    setState({ ...state, showTooltipButton: data })
                  }
                />
              </AccordionDetails>
            </Accordion>
            {state.showTooltipButton && (
              <Accordion className="html-text-styler-accordion">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Help tooltip</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <DropDown
                    heading="Font Family"
                    options={fontFamilyList}
                    isDisabled={!state.showVisualHeader}
                    defaultValue={state.visualHeaderTooltipFontFamily}
                    passDropDownData={(data) =>
                      setState({
                        ...state,
                        visualHeaderTooltipFontFamily: data,
                      })
                    }
                  />
                  <InputField
                    heading="Font Size"
                    placeholder="Font Size"
                    type="number"
                    isDisabled={!state.showVisualHeader}
                    defaultValue={state.visualHeaderTooltipFontSize}
                    passInputFieldData={(data) =>
                      setState({ ...state, visualHeaderTooltipFontSize: data })
                    }
                  />
                  <ColorPicker
                    heading="Color"
                    isDisabled={!state.showVisualHeader}
                    defaultValue={state.visualHeaderTooltipColor}
                    passColorPickerData={(data) =>
                      setState({ ...state, visualHeaderTooltipColor: data })
                    }
                  />
                  <ColorPicker
                    heading="Background"
                    isDisabled={!state.showVisualHeader}
                    defaultValue={state.visualHeaderTooltipBackground}
                    passColorPickerData={(data) =>
                      setState({
                        ...state,
                        visualHeaderTooltipBackground: data,
                      })
                    }
                  />
                  <InputSlider
                    heading="Transparency"
                    placeholder="Transparency"
                    isDisabled={!state.showVisualHeader}
                    defaultValue={state.visualHeaderTooltipTransparency}
                    passInputFieldData={(data) =>
                      setState({
                        ...state,
                        visualHeaderTooltipTransparency: data,
                      })
                    }
                    maxValue={100}
                    suffix="%"
                  />
                </AccordionDetails>
              </Accordion>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion className="html-text-styler-accordion html-text-styler-subaccordion">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Tooltips</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SwitchField
              isAccordionSwitch={true}
              isDisabled={false}
              heading=""
              defaultValue={state.showVisualTooltip}
              passSwitchFieldData={(data) =>
                setState({ ...state, showVisualTooltip: data })
              }
            />
            <DropDown
              heading="Type"
              options={tooltiptList}
              isDisabled={!state.showVisualTooltip}
              defaultValue={state.visualType}
              passDropDownData={(data) =>
                setState({ ...state, visualType: data })
              }
            />
            <Accordion className="html-text-styler-accordion">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Text</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DropDown
                  heading="Font Family"
                  options={fontFamilyList}
                  isDisabled={!state.showVisualTooltip}
                  defaultValue={state.visualTypeFontFamily}
                  passDropDownData={(data) =>
                    setState({ ...state, visualTypeFontFamily: data })
                  }
                />
                <InputField
                  heading="Font Size"
                  placeholder="Font Size"
                  type="number"
                  isDisabled={!state.showVisualTooltip}
                  defaultValue={state.visualTypeFontSize}
                  passInputFieldData={(data) =>
                    setState({ ...state, visualTypeFontSize: data })
                  }
                />
                <FontStyle
                  heading="Font Style"
                  isDisabled={!state.showVisualTooltip}
                  defaultBoldValue={state.visualTypeBold}
                  defaultItalicValue={state.visualTypeItalic}
                  defaultUnderlineValue={state.visualTypeUnderline}
                  passBoldData={(data) =>
                    setState({ ...state, visualTypeBold: data })
                  }
                  passItalicData={(data) =>
                    setState({ ...state, visualTypeItalic: data })
                  }
                  passUnderlineData={(data) =>
                    setState({ ...state, visualTypeUnderline: data })
                  }
                />
                <ColorPicker
                  heading="Label"
                  isDisabled={!state.showVisualTooltip}
                  defaultValue={state.visualTypeTitleFontColor}
                  passColorPickerData={(data) =>
                    setState({ ...state, visualTypeTitleFontColor: data })
                  }
                />
                <ColorPicker
                  heading="Value"
                  isDisabled={!state.showVisualTooltip}
                  defaultValue={state.visualTypeValueFontColor}
                  passColorPickerData={(data) =>
                    setState({ ...state, visualTypeValueFontColor: data })
                  }
                />
              </AccordionDetails>
            </Accordion>
            <Accordion className="html-text-styler-accordion">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Background</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ColorPicker
                  heading="Color"
                  isDisabled={!state.showVisualTooltip}
                  defaultValue={state.visualTypeBackground}
                  passColorPickerData={(data) =>
                    setState({ ...state, visualTypeBackground: data })
                  }
                />
                <InputSlider
                  heading="Transparency"
                  placeholder="Transparency"
                  isDisabled={!state.showVisualTooltip}
                  defaultValue={state.visualTypeTransparency}
                  passInputFieldData={(data) =>
                    setState({ ...state, visualTypeTransparency: data })
                  }
                  maxValue={100}
                  suffix="%"
                />
              </AccordionDetails>
            </Accordion>
          </AccordionDetails>
        </Accordion>
      </TabPanel>
      <DownloadButton data={state} text="Download" disabled={checkDisabled()} />
    </PageWrapper>
  );
};

export default Home;

const PageWrapper = styled.div`
  max-width: 600px;
  margin: 30px auto 25px;
  padding: 0px 15px;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}
