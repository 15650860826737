import { Fragment, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Label from "./Label";

const FontStyle = (props) => {
  const [boldValue, setBoldValue] = useState(props.defaultBoldValue);
  const [italicValue, setItalicValue] = useState(props.defaultItalicValue);
  const [underlineValue, setUnderlineValue] = useState(
    props.defaultUnderlineValue
  );

  const handleBoldChange = (event) => {
    setBoldValue(event.target.checked);
    props.passBoldData(event.target.value);
  };

  const handleItalicChange = (event) => {
    setItalicValue(event.target.checked);
    props.passItalicData(event.target.checked);
  };

  const handleUnderlineChange = (event) => {
    setUnderlineValue(event.target.checked);
    props.passUnderlineData(event.target.checked);
  };

  return (
    <Fragment>
      <Label label={props.heading} isDisabled={props.isDisabled} />
      <FormControl
        style={{
          marginBottom: "10px",
        }}
        className={
          "html-text-styler-checkbox-font-style " +
          (props.isDisabled ? "html-text-styler-disabled" : "")
        }
      >
        <FormGroup>
          <FormControlLabel
            value="Bold"
            disabled={props.isDisabled}
            checked={boldValue}
            onChange={handleBoldChange}
            control={<Checkbox />}
            label={<FormatBoldIcon />}
          />
          <FormControlLabel
            value="Italic"
            disabled={props.isDisabled}
            checked={italicValue}
            onChange={handleItalicChange}
            control={<Checkbox />}
            label={<FormatItalicIcon />}
          />
          <FormControlLabel
            value="Underline"
            disabled={props.isDisabled}
            checked={underlineValue}
            onChange={handleUnderlineChange}
            control={<Checkbox />}
            label={<FormatUnderlinedIcon />}
          />
        </FormGroup>
      </FormControl>
    </Fragment>
  );
};

export default FontStyle;
