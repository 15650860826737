import { Fragment, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import Label from "./Label";

const Align = (props) => {
  const [value, setValue] = useState(props.defaultValue);

  const handleChange = (event) => {
    setValue(event.target.value);
    props.passAlignData(event.target.value);
  };

  return (
    <Fragment>
      <Label label={props.heading} isDisabled={props.isDisabled} />
      <FormControl
        style={{
          marginBottom: "10px",
        }}
      >
        <RadioGroup
          defaultValue={props.defaultValue}
          value={value}
          onChange={handleChange}
          className={
            "html-text-styler-radio-group-align " +
            (props.isDisabled ? "html-text-styler-disabled" : "")
          }
        >
          <FormControlLabel
            value="Left"
            disabled={props.isDisabled}
            control={<Radio />}
            label={<FormatAlignLeftIcon />}
          />
          <FormControlLabel
            value="Center"
            disabled={props.isDisabled}
            control={<Radio />}
            label={<FormatAlignCenterIcon />}
          />
          <FormControlLabel
            value="Right"
            disabled={props.isDisabled}
            control={<Radio />}
            label={<FormatAlignRightIcon />}
          />
        </RadioGroup>
      </FormControl>
    </Fragment>
  );
};

export default Align;
