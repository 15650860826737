import styled from "styled-components";

const MainHeading = (props) => {
  return <HeadingWrapper>{props.heading}</HeadingWrapper>;
};

export default MainHeading;

const HeadingWrapper = styled.h1`
  text-align: center;
  font-size: 30px;
  margin: 10px auto 30px;
`;
