import { Fragment, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Label from "./Label";

const DropDown = (props) => {
  const [value, setValue] = useState(props.defaultValue);

  const handleChange = (event) => {
    setValue(event.target.value);
    props.passDropDownData(event.target.value);
  };

  return (
    <Fragment>
      <Label label={props.heading} isDisabled={props.isDisabled} />
      <FormControl
        fullWidth
        style={{
          marginBottom: "10px",
        }}
      >
        <Select
          className={
            "html-text-styler-dropdown " +
            (props.isDisabled ? "html-text-styler-disabled" : "")
          }
          value={value}
          onChange={handleChange}
          displayEmpty
          disabled={props.isDisabled}
        >
          <MenuItem disabled value="">
            Select
          </MenuItem>
          {props.options.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.name ? option.name : option.value}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Fragment>
  );
};

export default DropDown;
