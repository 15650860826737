import styled from "styled-components";

const Label = (props) => {
  return (
    <LabelWrapper
      className={props.isDisabled ? "html-text-styler-disabled" : ""}
    >
      {props.label}
    </LabelWrapper>
  );
};

export default Label;

const LabelWrapper = styled.div`
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 10px;
  font-family: "Raleway", sans-serif;
`;
