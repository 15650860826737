import { Fragment, useState } from "react";
import TextField from "@mui/material/TextField";
import Label from "./Label";

const InputField = (props) => {
  const [input, setInput] = useState(props.defaultValue);

  const handleChange = (event) => {
    if (
      props.type === "text" ||
      (props.type === "number" &&
        props.maxValue &&
        event.target.value <= props.maxValue &&
        event.target.value >= 0) ||
      (props.type === "number" && !props.maxValue)
    ) {
      setInput(event.target.value);
      props.passInputFieldData(event.target.value);
    }
  };

  return (
    <Fragment>
      <Label isDisabled={props.isDisabled} label={props.heading} />
      <TextField
        className={
          "html-text-styler-input-field " +
          (props.isDisabled ? "html-text-styler-disabled" : "")
        }
        fullWidth
        disabled={props.isDisabled}
        type={props.type ? props.type : "text"}
        id="outlined-basic"
        placeholder={props.placeholder}
        variant="outlined"
        onChange={handleChange}
        value={input}
        InputProps={{ inputProps: { min: 0, max: props.maxValue } }}
      />
    </Fragment>
  );
};

export default InputField;
