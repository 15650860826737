import { Fragment, useState } from "react";
import TextField from "@mui/material/TextField";
import Slider from "@mui/material/Slider";
import styled from "styled-components";
import Label from "./Label";

const InputSlider = (props) => {
  const [input, setInput] = useState(props.defaultValue);

  const handleChange = (event) => {
    if (
      (props.maxValue &&
        event.target.value <= props.maxValue &&
        event.target.value >= 0) ||
      !props.maxValue
    ) {
      setInput(+event.target.value);
      props.passInputFieldData(event.target.value);
    }
  };

  return (
    <Fragment>
      <Label isDisabled={props.isDisabled} label={props.heading} />
      <InputSliderWrapper>
        <TextField
          className={
            "html-text-styler-input-field " +
            (props.isDisabled ? "html-text-styler-disabled " : "") +
            (props.suffix === "%" ? "html-text-styler-input-field-perc " : "") +
            (props.suffix === "px" ? "html-text-styler-input-field-px " : "")
          }
          disabled={props.isDisabled}
          type="number"
          id="outlined-basic"
          placeholder={props.placeholder}
          variant="outlined"
          onChange={handleChange}
          value={input}
          InputProps={{ inputProps: { min: 0, max: props.maxValue } }}
        />
        <Slider
          className="html-text-styler-slider"
          value={input}
          disabled={props.isDisabled}
          onChange={handleChange}
          max={props.maxValue}
        />
      </InputSliderWrapper>
    </Fragment>
  );
};

export default InputSlider;

const InputSliderWrapper = styled.div`
  display: flex;
  align-items: center;
`;
