import styled from "styled-components";
import Button from "@mui/material/Button";
import { saveJson } from "../../../services/api";

const DownloadButton = (props) => {
  let downloadLink = "";

  const createJson = (data) => {
    let json = {
      name: "HTML Text Styler Theme",
      visualStyles: {
        textStylerD577C9FF21FB4883A1CE1335C340B54F: {
          "*": {
            paidFeature: [
              {
                show: true,
              },
              { licenseKey: data.licenseKey },
            ],
            contentFormatting: [
              {
                showAsText: data.showAsText,
              },
              {
                scalingPercentage: data.scalingPercentage,
              },
              {
                fullWidth: data.fullWidth,
              },
              {
                hideScrollBar: data.hideScrollBar,
              },
            ],
            fontFormatting: [
              {
                fontColor: { solid: { color: data.fontColor } },
                fontSize: data.fontSize,
                fontFamily: data.fontFamily,
                fontAlign: data.fontAlign,
              },
            ],
            gridFormatting: [
              {
                show: data.showGrid,
              },
              {
                outlineThickness: data.outlineThickness,
              },
              {
                rowPadding: data.rowPadding,
              },
              {
                columnPadding: data.columnPadding,
              },
              {
                outlineColor: {
                  solid: {
                    color: data.outlineColor,
                  },
                },
              },
            ],
            lockAspect: [
              {
                show: data.lockAspect,
              },
            ],
            keepLayerOrder: [
              {
                show: data.keepLayerOrder,
              },
            ],
            title: [
              {
                show: data.showTitle,
                fontFamily: data.titleFontFamily,
                fontSize: data.titleFontSize,
                bold: data.titleBold,
                italic: data.titleItalic,
                underline: data.titleUnderline,
                fontColor: { solid: { color: data.titleFontColor } },
                background: { solid: { color: data.titleBackground } },
                alignment: data.titleAlignment,
                titleWrap: data.titleWrap,
              },
            ],
            background: [
              {
                show: data.showBackground,
                color: { solid: { color: data.backgroundColor } },
                transparency: data.backgroundTransparency,
              },
            ],
            border: [
              {
                show: data.showVisualBorder,
                color: { solid: { color: data.visualBorderColor } },
                radius: data.visualBorderRadius,
              },
            ],
            dropShadow: [
              {
                show: data.showShadow,
                color: { solid: { color: data.showShadowColor } },
                position: data.showShadowPosition,
                preset: data.showShadowPreset,
                shadowSpread: data.showShadowSize,
                shadowBlur: data.showShadoBlur,
                angle: data.showShadowAngle,
                shadowDistance: data.showShadowDistance,
                transparency: data.showShadowTransparency,
              },
            ],
            visualHeader: [
              {
                show: data.showVisualHeader,
                background: { solid: { color: data.visualHeaderBackground } },
                border: { solid: { color: data.visualHeaderBorder } },
                foreground: { solid: { color: data.visualHeaderForeground } },
                transparency: data.visualHeaderTransparency,
                showVisualInformationButton: data.showVisualInformationButton,
                showVisualWarningButton: data.showVisualWarningButton,
                showVisualErrorButton: data.showVisualErrorButton,
                showDrillRoleSelector: data.showDrillRoleSelector,
                showDrillUpButton: data.showDrillUpButton,
                showDrillToggleButton: data.showDrillToggleButton,
                showDrillDownLevelButton: data.showDrillDownLevelButton,
                showDrillDownExpandButton: data.showDrillDownExpandButton,
                showPinButton: data.showPinButton,
                showFocusModeButton: data.showFocusModeButton,
                showSeeDataLayoutToggleButton:
                  data.showSeeDataLayoutToggleButton,
                showOptionsMenu: data.showOptionsMenu,
                showFilterRestatementButton: data.showFilterRestatementButton,
                showTooltipButton: data.showTooltipButton,
              },
            ],
            visualHeaderTooltip: [
              {
                fontFamily: data.visualHeaderTooltipFontFamily,
                fontSize: data.visualHeaderTooltipFontSize,
                titleFontColor: {
                  solid: { color: data.visualHeaderTooltipColor },
                },
                background: {
                  solid: { color: data.visualHeaderTooltipBackground },
                },
                transparency: data.visualHeaderTooltipTransparency,
              },
            ],
            visualTooltip: [
              {
                show: data.showVisualTooltip,
                type: data.visualType,
                fontFamily: data.visualTypeFontFamily,
                fontSize: data.visualTypeFontSize,
                bold: data.visualTypeBold,
                italic: data.visualTypeItalic,
                underline: data.visualTypeUnderline,
                titleFontColor: {
                  solid: { color: data.visualTypeTitleFontColor },
                },
                valueFontColor: {
                  solid: { color: data.visualTypeValueFontColor },
                },
                background: { solid: { color: data.visualTypeBackground } },
                transparency: data.visualTypeTransparency,
              },
            ],
          },
        },
      },
    };
    return JSON.stringify(json, null, "\t");
  };

  const saveJsonToDatavase = async () => {
    try {
      await saveJson(props.data);
    } catch (err) {
      console.log(err);
      // throw new Error("Something went wrong!");
    }
  };

  if (props.data) {
    const data = new Blob([createJson(props.data)], {
      type: "application/json",
    });
    downloadLink = window.URL.createObjectURL(data);
  }

  return (
    <ButtonWrapper>
      <Button
        download="html-text-styler-theme"
        href={downloadLink}
        variant="contained"
        disabled={props.disabled}
        onClick={saveJsonToDatavase}
      >
        {props.text}
      </Button>
    </ButtonWrapper>
  );
};

export default DownloadButton;

const ButtonWrapper = styled.div`
  margin: 10px 0px;
  text-align: center;
`;
