import { Fragment, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const SwitchField = (props) => {
  const [checked, setChecked] = useState(props.defaultValue);

  const handleChange = (event) => {
    event.stopPropagation();
    setChecked(event.target.checked);
    props.passSwitchFieldData(event.target.checked);
  };

  return (
    <Fragment>
      <FormControlLabel
        className={
          "html-text-styler-switch " +
          (props.isAccordionSwitch ? "accordion-switch" : "") +
          (props.isDisabled ? "html-text-styler-disabled" : "")
        }
        labelPlacement="start"
        control={
          <Switch
            disabled={props.isDisabled}
            checked={checked}
            onChange={handleChange}
          />
        }
        label={props.heading}
      />
    </Fragment>
  );
};

export default SwitchField;
