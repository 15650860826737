import { Fragment, useState } from "react";
import { SketchPicker } from "react-color";
import styled from "styled-components";
import Label from "./Label";

const ColorPicker = (props) => {
  const [color, setColor] = useState(props.defaultValue);
  const [displayColor, setDisplayColor] = useState(false);

  const handleClick = () => {
    if (!props.isDisabled) {
      setDisplayColor(true);
    }
  };

  const handleClose = () => {
    setDisplayColor(false);
  };

  const handleChange = (color) => {
    setColor(color.hex);
  };

  const handleChangeComplete = (color) => {
    setColor(color.hex);
    props.passColorPickerData(color.hex);
  };

  return (
    <Fragment>
      <Label isDisabled={props.isDisabled} label={props.heading} />
      <Swatch
        className={props.isDisabled ? "html-text-styler-disabled" : ""}
        onClick={handleClick}
      >
        <Color style={{ backgroundColor: color }} />
      </Swatch>
      {displayColor && !props.isDisabled ? (
        <PopOver>
          <Cover onClick={handleClose} />
          <SketchPicker
            color={color}
            onChange={handleChange}
            onChangeComplete={handleChangeComplete}
          />
        </PopOver>
      ) : null}
    </Fragment>
  );
};

export default ColorPicker;

const Swatch = styled.div`
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 10%);
  display: inline-block;
  cursor: pointer;
`;

const Color = styled.div`
  width: 36px;
  height: 14px;
  border-radius: 2px;
`;

const PopOver = styled.div`
  position: absolute;
  z-index: 2;
`;

const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
